.heading {
    margin: 0;
    padding: 0px;
    color: white;
    text-align: center;
  }
  
.heading > h1 {
  padding: 20px;
  margin: 0;
}
.styleButton {
  margin: 0.5em;
  color: #444;
}