.geoapify{

    &-close-button{	/*The clear button*/
        color:rgb(218, 98, 0);
        
    }

    &-autocomplete{
        &-input{  /*The input element*/
            border-radius: 1em;
            font-size: 1.3rem;
            padding: 1em;
        }

        &-items{	/*The dropdown list*/
            color:rgb(218, 98, 0);
        }

    }

}

.geocoder-container{
    padding: 1em;

    }
    