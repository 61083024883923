.weather-code-temp{
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2192d3;
    color: #fff;
    font-size: 1.2rem;
    padding: 0.2rem;

}