@import "https://unpkg.com/leaflet@1.9.2/dist/leaflet.css";

.leaflet-container {
    width: 100%;
    height: 500px;
  }

  .test{
    width: auto;
    height: auto;
  }

  .weather-map-wind {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }