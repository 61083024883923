.App {
  width: 80vw;
  margin: auto;
  padding: 1rem 0;
}

@media screen and (max-width: 900px) {
  .App {
    width: 95vw;
  }
}