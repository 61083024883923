
.weather-current {
    padding: 1rem;
    color: #fff;
    font-size: 2.5rem;
    border-bottom: 1px solid #fff;

    &-header{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;
        
        }

    &-temperature{
        font-size: 6rem;
        }

    &-details{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        &-rain{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-pressure{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    
    }

    &-details-wind{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-rain{
        margin-left: 1rem;
    }

    &-pressure{
        margin-left: 1rem;
    }


}







